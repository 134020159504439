import React, { useEffect, useState } from "react";
//Service
import api from "../../../../Services/api";

//Toast
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Components
import { InputWrap } from "../Management/Legislation/AddLei/style";
//import SidebarOptions from "../../../../Components/SidebarOptions";
import ArrowBack from "../../../../Components/ResponsiveArrowBack";
//Icons
import SearchIcon from "@material-ui/icons/Search";

import "./index.css";
import ModalDelete from "./modal";
import ModalPorteiro from "./modalPorteiro";
import NewSideBar from "../../../../Components/NewComponents/NewSideBar";

export default function ResidentListAdministration() {

  useEffect(() => {
    getData();
    getEmployee();
    return () => {
      setResidents([]);
      setEmployees([]);
    };
  }, []);

  const [residents, setResidents] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [procurar, setProcurar] = useState("");
  const [filter, setFilter] = useState("name");

  async function getData() {
    try {
      const response = await api.get("/resident?fields=id,name,modality,complement,email");

      const residents = response.data.map((resident) => ({
        name: resident.name,
        modality: resident.modality === "resident" ? "Condômino" : "Síndico",
        complement: resident.complement,
        email: resident.email,
        id: resident.id,
      }));

      setResidents(residents);

    } catch (error) {
      throw error;
    }
  }

  async function getEmployee() {
    try {
      const response = await api.get("/employee?fields=id,name,modality,email");

      const employees = response.data.map((employee) => ({
        name: employee.name,
        modality:
          employee.modality === "doorman" ? "Porteiro" : "Administrador",
        email: employee.email,
        id: employee.id,
      }));

      setEmployees(employees);

    } catch (error) {
      throw error;
    }
  }

  return (
    <div
      style={{
        width: "100vw",
        display: "flex",
        height: "100vh",
      }}
    >
      <NewSideBar rota={"employee"} tipo={"Administrativo"} />
      <div id="safeArea" style={{ overflowY: "auto", width: "100%" }}>
        <div
          className="pageTitleContainer"
          id="x"
          style={{ marginLeft: "56px" }}
        >
          <ArrowBack />

          <p id="pageTitle">Condôminos Cadastrados</p>
        </div>

        <div id="listArea" style={{ marginLeft: "56px" }}>
          <div style={{ display: "flex" }}>
            <div id="searchContainer">
              <SearchIcon />
              <input
                id="searchInput"
                placeholder="Pesquisar"
                onChange={(event) => {
                  setProcurar(event.target.value);
                }}
              />
            </div>
            <InputWrap
              style={{
                marginBottom: "32px",
                marginLeft: "10px",
                width: "200px",
              }}
            >
              <select
                style={{
                  border: "none",
                  borderLeft: "4px solid #00334E",
                  borderRadius: "20px",
                  minWidth: "150px",
                  backgroundColor: "#f8f8f8",
                  padding: "4px 12px",
                }}
                placeholder="None"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              >
                <option value="name">Nome</option>
                <option value="modality">Modalidade</option>
                <option value="complement">Complemento</option>
                <option value="email">Email</option>
              </select>
            </InputWrap>
          </div>

          {residents
            .filter((resident) => {
              if (procurar == "") {
                return resident;
              } else if (
                resident?.name.toLowerCase().includes(procurar.toLowerCase()) &&
                filter === "name"
              ) {
                return resident;
              } else if (
                resident?.email
                  .toLowerCase()
                  .includes(procurar.toLowerCase()) &&
                filter === "email"
              ) {
                return resident;
              } else if (
                resident?.modality
                  ? resident?.modality
                    .toLowerCase()
                    .includes(procurar.toLowerCase()) && filter === "modality"
                  : null
              ) {
                return resident;
              } else if (
                resident?.complement
                  ? resident?.complement
                    .toLowerCase()
                    .includes(procurar.toLowerCase()) &&
                  filter === "complement"
                  : null
              ) {
                return resident;
              }
            })
            .map((resident) => (
              <div className="data-container">
                <div id="data-safe-area">
                  <div className="manager-name-title">
                    Nome: {resident?.name}
                  </div>
                  <div className="manager-name-title">
                    Email: {resident?.email}
                  </div>
                  <div className="manager-name-title">
                    Modalidade: {resident?.modality}
                  </div>
                  <div className="manager-name-title">
                    Complemento:{" "}
                    {(resident?.complement
                      ? resident?.complement
                      : "Não está definido"
                    ).replace(/:/g, " ")}
                  </div>
                </div>
                <div className="WarnDate"></div>
                <ModalDelete
                  id={resident?.id}
                  setResidents={setResidents}
                ></ModalDelete>
              </div>
            ))}

          {employees
            .filter((resident) => {
              if (procurar == "") {
                return resident;
              } else if (
                resident?.name.toLowerCase().includes(procurar.toLowerCase()) &&
                filter === "name"
              ) {
                return resident;
              } else if (
                resident?.email
                  .toLowerCase()
                  .includes(procurar.toLowerCase()) &&
                filter === "email"
              ) {
                return resident;
              } else if (
                resident?.modality
                  ? resident?.modality
                    .toLowerCase()
                    .includes(procurar.toLowerCase()) && filter === "modality"
                  : null
              ) {
                return resident;
              } else if (
                resident?.complement
                  ? resident?.complement
                    .toLowerCase()
                    .includes(procurar.toLowerCase()) &&
                  filter === "complement"
                  : null
              ) {
                return resident;
              }
            })
            .map((resident) => (
              <div className="data-container">
                <div id="data-safe-area">
                  <div className="manager-name-title">
                    Nome: {resident?.name}
                  </div>
                  <div className="manager-name-title">
                    Email: {resident?.email}
                  </div>
                  <div className="manager-name-title">
                    Modalidade: {resident?.modality}
                  </div>
                </div>
                <div className="WarnDate"></div>
                <ModalPorteiro
                  id={resident?.id}
                  setEmployees={setEmployees}
                ></ModalPorteiro>
              </div>
            ))}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
