import React, { useState } from "react";

import { Grid } from "@material-ui/core";

import ArrowBack from "../../../../../Components/ResponsiveArrowBack";

import Sidebar from "../../../../../Components/SidebarManager";

import {
  ButtonDecision,
  ButtonDecisionActive,
  Container,
  ContainerButton,
  ContainerDecision,
  ContainerInput,
  ContainerInputs,
  ContainerParking,
  Input,
  InputParking,
  LabelInput,
  SendVisitor,
  TextEmpty,
} from "./styles";

import api from "../../../../../Services/api";

import { Oval } from "react-loader-spinner";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function CreateVisitorManager() {
  const [name, setName] = useState("");
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const [rg, setRg] = useState("");
  const [duration, setDuration] = useState("");
  const [license, setLicense] = useState("");
  const [color, setColor] = useState("");
  const [model, setModel] = useState("");
  const [isYesClicked, setIsYesClicked] = useState(false);
  const [isNoClicked, setIsNoClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const notifyDelete = () =>
    toast.error("Algo aconteceu de errado. Tente novamente mais tarde!");
  const notifySucess = () => toast.success("Visitante adicionado!");
  const notifyError = () => toast.error("Preencha todos os campos!");

  function YesPressed() {
    if (isYesClicked === false) {
      setIsYesClicked(true);
      setIsNoClicked(false);
    } else {
      setIsYesClicked(false);
    }
  }

  function NoPressed() {
    if (isNoClicked === false) {
      setIsNoClicked(true);
      setIsYesClicked(false);
    } else {
      setIsNoClicked(false);
    }
  }

  async function handleSubmit() {
    setLoading(true);
    if (rg.length === 0  || name.length === 0 || date.length === 0) {
      notifyError();
      setLoading(false);
      return;
    }
    try {
      if (isYesClicked === true) {
        const data = {
          visitors: name,
          date: date,
          plate: license,
          color: color,
          model: model,
          status: "vaga reservada",
          document: rg,
          duration: duration,
        };

        if (license.length === 0 || color.length === 0 || model.length === 0) {
          notifyError();
          setLoading(false);
          return;
        }

        await api.post("/visitor", data);
        setLoading(false);
        setName("");
        setRg("");
        setDate("");
        setColor("");
        setModel("");
        setLicense("");
        notifySucess();
      } else {
        const data = {
          visitors: name,
          date: date,
          status: "vaga não reservada",
          document: rg,
          duration: duration,
        };
        await api.post("/visitor", data);
        setLoading(false);
        setName("");
        setRg("");
        setDate("");
        notifySucess();
      }
    } catch (err) {
      setLoading(false);
      notifyDelete();
    }
  }

  return (
    <>
      <Grid container>
        <Sidebar />
        <Grid
          container
          item
          xs={8}
          style={{ marginBottom: "40px", marginLeft: "33vw" }}
        >
          <ContainerButton>
            <ArrowBack />

            <p id="pageTitle">Adicionar Visitantes</p>
          </ContainerButton>
          <Container>
            <ContainerInput>
              <LabelInput>Nome do visitante:</LabelInput>
              <Input value={name} onChange={(e) => setName(e.target.value)} />
            </ContainerInput>
            <ContainerInput>
              <LabelInput>Data da visita:</LabelInput>
              <Input
                value={date}
                onChange={(e) => setDate(e.target.value)}
                type="date"
                format="dd/MM/yyyy"
                min={new Date().toISOString().split("T")[0]}
              />
            </ContainerInput>
            <ContainerInput>
              <LabelInput>Número do documento:</LabelInput>
              <Input value={rg} onChange={(e) => setRg(e.target.value)} />
            </ContainerInput>
            <ContainerInput>
              <LabelInput>Duração do cadastro (dias):</LabelInput>
              <Input type="number" value={duration} onChange={(e) => setDuration(e.target.value)} />
            </ContainerInput>
            <TextEmpty>Deseja reservar vaga de estacionamento?</TextEmpty>
            <ContainerParking>
              <ContainerDecision>
                {isYesClicked ? (
                  <ButtonDecisionActive onClick={() => YesPressed()}>
                    SIM
                  </ButtonDecisionActive>
                ) : (
                  <ButtonDecision onClick={() => YesPressed()}>
                    SIM
                  </ButtonDecision>
                )}

                {isNoClicked ? (
                  <ButtonDecisionActive onClick={() => NoPressed()}>
                    NÃO
                  </ButtonDecisionActive>
                ) : (
                  <ButtonDecision onClick={() => NoPressed()}>
                    NÃO
                  </ButtonDecision>
                )}
              </ContainerDecision>

              <ContainerInputs>
                <InputParking
                  placeholder="Placa do veículo"
                  disabled={isNoClicked}
                  value={license}
                  onChange={(e) => setLicense(e.target.value)}
                />
                <InputParking
                  placeholder="Cor do veículo"
                  disabled={isNoClicked}
                  value={color}
                  onChange={(e) => setColor(e.target.value)}
                />
                <InputParking
                  placeholder="Modelo do Veículo"
                  disabled={isNoClicked}
                  value={model}
                  onChange={(e) => setModel(e.target.value)}
                />
              </ContainerInputs>
              <SendVisitor
                onClick={() => handleSubmit()}
                disabled={loading ? "none" : "auto"}
              >
                {loading ? (
                  <Oval
                    ariaLabel="loading-indicator"
                    height={30}
                    width={30}
                    strokeWidth={5}
                    strokeWidthSecondary={1}
                    color="#1D6995"
                    secondaryColor="white"
                  />
                ) : (
                  "Enviar"
                )}
              </SendVisitor>
            </ContainerParking>
          </Container>
        </Grid>
      </Grid>
    </>
  );
}
