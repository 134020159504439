import React, { useState, useEffect } from "react";

import { Grid } from "@material-ui/core";

import ArrowBack from "../../../../../Components/ResponsiveArrowBack";

import Sidebar from "../../../../../Components/SidebarManager";

import { InputWrap } from "../../Management/Legislation/AddLei/style";
import DatePicker from "react-date-picker";

import SearchIcon from "@material-ui/icons/Search";

import {
  Container,
  ContainerButton,
  ContainerCard,
  ContainerLoading,
  ContainerTrash,
  Line,
  TextEmpty,
  TextVisitor,
  Tittle,
  ViewName,
} from "./styles";

import api from "../../../../../Services/api";

import { Oval } from "react-loader-spinner";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { GoTrashcan } from "react-icons/go";

export default function SeeHistoryManager() {
  const [visitorList, setVisitorList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [data, setData] = useState();
  const [procurar, setProcurar] = useState("");
  const [filter, setFilter] = useState("visitors");
  const notifyError = () =>
    toast.error("Erro ao buscar lista de visitantes, faça login novamente!");
  const notifySucess = () => toast.success("Visitante exluído!");
  let userid = localStorage.getItem("@SWCondominio:user");
  const idUser = JSON.parse(userid);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    setLoading(true);
    try {
      const response = await api.get("/visitor");
      setVisitorList(response.data);
      console.log(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notifyError();
      throw error;
    }
  }

  async function deleteVisitor(id) {
    setLoadingDelete(true);
    try {
      await api.delete(`/visitor/${id}`);
      notifySucess();
      getData();
      setLoadingDelete(false);
    } catch (error) {
      notifyError();
      setLoadingDelete(false);
      throw error;
    }
  }

  function onChangeData(data) {
    setData(data);
    setProcurar("");
  }

  function formatDate(dateString) {
    try {
      const datePart = dateString.split('T')[0];
      const [year, month, day] = datePart.split('-');
      return `${day}/${month}/${year}`;
    } catch (error) {
      return "Indefinido";
    }
}


  return (
    <>
      <Grid container>
        <Sidebar />
        <Grid
          container
          item
          xs={8}
          style={{ marginBottom: "40px", marginLeft: "33vw" }}
        >
          <ContainerButton>
            <ArrowBack />

            <p id="pageTitle">Histórico Visitantes</p>
          </ContainerButton>
          <Container>
            <div style={{ display: "flex" }}>
              <div id="searchContainer">
                {filter === "date" ? (
                  <>
                    <SearchIcon style={{ marginRight: "20px" }} />
                    <DatePicker
                      value={data}
                      onChange={(event) => onChangeData(event)}
                    />
                  </>
                ) : (
                  <>
                    <SearchIcon />
                    <input
                      id="searchInput"
                      placeholder="Pesquisar"
                      onChange={(event) => {
                        setProcurar(event.target.value);
                      }}
                    />
                  </>
                )}
              </div>
              <InputWrap
                style={{
                  marginBottom: "32px",
                  marginLeft: "10px",
                  width: "200px",
                }}
              >
                <select
                  style={{
                    border: "none",
                    borderLeft: "4px solid #00334E",
                    borderRadius: "20px",
                    minWidth: "150px",
                    backgroundColor: "#f8f8f8",
                    padding: "4px 12px",
                  }}
                  placeholder="None"
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                >
                  <option value="visitors">Nome</option>
                  <option value="date">Data</option>
                  <option value="status">Status</option>
                  <option value="complement">Unidade</option>
                </select>
              </InputWrap>
            </div>
            {loading ? (
              <ContainerLoading>
                <Oval
                  ariaLabel="loading-indicator"
                  height={100}
                  width={100}
                  strokeWidth={5}
                  strokeWidthSecondary={1}
                  color="#1D6995"
                  secondaryColor="white"
                />
              </ContainerLoading>
            ) : visitorList.length > 0 ? (
              visitorList
                .filter((value) => {
                  if (procurar === "" && data === "") {
                    return value;
                  } else if (
                    value?.visitors
                      .toLowerCase()
                      .includes(procurar.toLowerCase()) &&
                    filter === "visitors"
                  ) {
                    return value;
                  } else if (
                    value?.date.includes(
                      data ? data.toISOString().substr(0, 10) : ""
                    ) &&
                    filter === "date"
                  ) {
                    return value;
                  } else if (
                    value?.status.includes(procurar.toLowerCase()) &&
                    filter === "status"
                  ) {
                    return value;
                  } else if (
                    value?.complement
                      ? value?.complement
                          .toLowerCase()
                          .includes(procurar.toLowerCase()) &&
                        filter === "complement"
                      : null
                  ) {
                    return value;
                  }
                })
                .map((value) => (
                  <ContainerCard>
                    <ViewName>
                      <Tittle>Nome: {value.visitors}</Tittle>
                      {idUser.id === value.resident_id ? (
                        loadingDelete ? (
                          <ContainerTrash>
                            <Oval
                              ariaLabel="loading-indicator"
                              height={20}
                              width={20}
                              strokeWidth={5}
                              strokeWidthSecondary={1}
                              color="#1D6995"
                              secondaryColor="white"
                            />
                          </ContainerTrash>
                        ) : (
                          <ContainerTrash
                            onClick={() => deleteVisitor(value.id)}
                          >
                            <GoTrashcan />
                          </ContainerTrash>
                        )
                      ) : null}
                    </ViewName>
                    <Line />
                    <TextVisitor>Documento: {value?.document}</TextVisitor>
                    <TextVisitor>
                      Data do cadastro: {formatDate(value?.date)}
                    </TextVisitor>
                    <TextVisitor>
                      Validade: {formatDate(value?.expiring_date)}
                    </TextVisitor>
                    <TextVisitor>Status: {value.status}</TextVisitor>
                    <TextVisitor>Unidade: {value.complement}</TextVisitor>
                    {value.status === "vaga reservada" ? (
                      <>
                        <Line />
                        <TextVisitor>Placa: {value.plate}</TextVisitor>
                        <TextVisitor>Modelo: {value.model}</TextVisitor>
                        <TextVisitor>Cor: {value.color}</TextVisitor>
                      </>
                    ) : null}
                  </ContainerCard>
                ))
            ) : (
              <ContainerLoading>
                <TextEmpty>Sem visitantes</TextEmpty>
              </ContainerLoading>
            )}
          </Container>
        </Grid>
      </Grid>
    </>
  );
}
