import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
//Service
import api from "../../../../Services/api";

//Toast
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Components
import Loading from "../../../../Components/Loading/index";
import Sidebar from "../../../../Components/SidebarAdmin";
import { InputWrap } from "../../Manager/Management/Legislation/AddLei/style";
//import SidebarOptions from "../../../../Components/SidebarOptions";
import ArrowBack from "../../../../Components/ResponsiveArrowBack";
//Icons
import SearchIcon from "@material-ui/icons/Search";

import "./index.css";
import ModalPorteiro from "../../Manager/ResidentsList/modalPorteiro";
import ModalDelete from "../../Manager/ResidentsList/modal";

export default function Users() {
  const notifyDelete = () => toast.error("Usuário Removido!");

  useEffect(() => {
    getData();
    getEmployee();
    return () => {
      setResidents([]);
      setEmployees([]);
    };
  }, []);

  const [residents, setResidents] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [procurar, setProcurar] = useState("");
  const [filter, setFilter] = useState('name');


  async function getData() {
    try {
      const response = await api.get("/resident");
      const residents = response.data.map((resident) => ({
        name: resident.name,
        email: resident.email,
        modality: resident.modality === "resident" ? "Condômino" : "Síndico",
        complement: resident.complement,
        id: resident.id,
        condominium_id: resident.condominium_id,
        condominium_name: resident.condominium_name
      }));
      setResidents(residents);
    } catch (error) {
      throw error;
    }
  }

  async function getEmployee() {
    try {
      const response = await api.get("/employee");
      const employees = response.data.map((employee) => ({
        name: employee.name,
        email: employee.email,
        modality: employee.modality === "doorman" ? "Porteiro" : employee.modality === "administration" ? "Administrativo" : "Técnico de Manutenção",
        id: employee.id,
        condominium_id: employee.condominium_id,
        condominium_name: employee.condominium_name
      }));
      setEmployees(employees);
    } catch (error) {
      throw error;
    }
  }

  async function deleteResident(res_id) {
    try {
      await api.delete(`/resident/${res_id}`);
      setResidents(residents.filter((resident) => resident.id !== res_id));
      notifyDelete();
      console.log('vapo')
    } catch (error) {
      alert(`Error: ${error.response.data.Error}`);
    }
  }

  async function deleteEmployee(res_id) {
    try {
      await api.delete(`/employee/${res_id}`);
      setEmployees(employees.filter((resident) => resident.id !== res_id));
      notifyDelete();
    } catch (error) {
      alert(`Error: ${error.response.data.error}`);
    }
  }

  return (
    <div id="homeContent" style={{ marginLeft: "11vw" }}>
      <Sidebar />
      <div id="safeArea">
        <div className="pageTitleContainer" id="x">
          <ArrowBack />

          <p id="pageTitle">Usuários Cadastrados</p>
        </div>

        <div id="listArea">
          <div style={{ display: 'flex' }}>
            <div id="searchContainer">
              <SearchIcon />
              <input
                id="searchInput"
                placeholder="Pesquisar"
                onChange={(event) => {
                  setProcurar(event.target.value);
                }}
              />
            </div>
            <InputWrap style={{ marginBottom: '32px', marginLeft: '10px', width: '200px' }}>
              <select style={{
                border: "none",
                borderLeft: "4px solid #00334E",
                borderRadius: "20px",
                minWidth: "150px",
                backgroundColor: "#f8f8f8",
                padding: "4px 12px"
              }}
                placeholder="None"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              >
                <option value="name">Nome</option>
                <option value="modality">Modalidade</option>
                <option value="condominium_name">Nome do Condomínio</option>
                <option value="email">Email</option>
              </select>
            </InputWrap>
          </div>

          {residents
            .filter((resident) => {
              if (procurar == "") {
                return resident;
              } else if (
                resident?.name
                  .toLowerCase()
                  .includes(procurar.toLowerCase()) && filter === "name"
              ) {
                return resident;
              }
              else if (
                resident?.modality ? (resident?.modality
                  .toLowerCase()
                  .includes(procurar.toLowerCase()) && filter === "modality") : null
              ) {
                return resident;
              }
              else if (
                resident?.condominium_name ? (resident?.condominium_name
                  .toLowerCase()
                  .includes(procurar.toLowerCase()) && filter === "condominium_name") : null
              ) {
                return resident;
              }
              else if (
                resident?.email ? (resident?.email
                  .toLowerCase()
                  .includes(procurar.toLowerCase()) && filter === "email") : null
              ) {
                return resident;
              }
            })
            .map((resident) => (
              <div className="data-container">
                <div id="data-safe-area">
                  <div className="manager-name-title">
                    Nome: {" "} {resident?.name}
                  </div>
                  <div className="manager-name-title" >
                    Email: {" "} {resident?.email}
                  </div>
                  <div className="manager-name-title">
                    Modalidade: {" "} {resident?.modality}
                  </div>
                  <div className="manager-name-title">
                    Complemento: {" "} {(resident?.complement ? resident?.complement : 'Não está definido').replace(/:/g, ' ')}
                  </div>
                  <div className="manager-name-title" >
                    Condomínio: {" "} {resident?.condominium_name}
                  </div>
                </div>
                <div className="WarnDate"></div>
                <ModalDelete id={resident?.id} onClick={() => console.log("oi", resident?.id)}> </ModalDelete>
              </div>
            ))}

          {employees
            .filter((resident) => {
              if (procurar == "") {
                return resident;
              } else if (
                resident?.name
                  .toLowerCase()
                  .includes(procurar.toLowerCase()) && filter === "name"
              ) {
                return resident;
              }
              else if (
                resident?.modality ? (resident?.modality
                  .toLowerCase()
                  .includes(procurar.toLowerCase()) && filter === "modality") : null
              ) {
                return resident;
              }
              else if (
                resident?.complement ? (resident?.complement
                  .toLowerCase()
                  .includes(procurar.toLowerCase()) && filter === "complement") : null
              ) {
                return resident;
              }
              else if (
                resident?.condominium_name ? (resident?.condominium_name
                  .toLowerCase()
                  .includes(procurar.toLowerCase()) && filter === "condominium_name") : null
              ) {
                return resident;
              }
              else if (
                resident?.email ? (resident?.email
                  .toLowerCase()
                  .includes(procurar.toLowerCase()) && filter === "email") : null
              ) {
                return resident;
              }
            })
            .map((resident) => (
              <div className="data-container">
                <div id="data-safe-area">
                  <div className="manager-name-title">
                    Nome: {" "} {resident?.name}
                  </div>
                  <div className="manager-name-title">
                    Email: {" "} {resident?.email}
                  </div>
                  <div className="manager-name-title">
                    Modalidade:{" "}
                    {resident?.modality}
                  </div>
                  <div className="manager-name-title" >
                    Condomínio: {" "} {resident?.condominium_name}
                  </div>
                </div>
                <div className="WarnDate"></div>
                <ModalPorteiro id={resident?.id} onClick={() => console.log("oi", resident?.id)} ></ModalPorteiro>
              </div>
            ))}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
