import React, { useEffect, useState } from "react";
import {
  Barra,
  CircleImg,
  DivButtons,
  DivCenter,
  DivText,
  FooterSide,
  Logo,
  LogoDiv,
  SideDiv,
  SideHeader,
  SideText,
} from "./styles";
import { isAuthenticated } from "../../../Context/isAuthenticated";
import { useAuth } from "../../../Context/auth";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import api from "../../../Services/api";
import sobre from "../../../Assets/info.svg";
import sair from "../../../Assets/logout.svg";
import profilePhoto from "../../../Assets/Logobranca.png";
import { H1text } from "../Header/styles";
import SideButtons from "../SideButtons";
import condCad from "../../../Assets/building.svg";
import propaganda from "../../../Assets/propaganda.svg";
import Approval from "../../../Assets/add-user.svg";
import Home from "../../../Assets/Home.svg";
import Users from "../../../Assets/users.svg";
import Warn from "../../../Assets/danger.svg";
import Spaces from "../../../Assets/spaces.svg";
import Gestao from "../../../Assets/management.svg";
import Docs from "../../../Assets/documents.svg";
import Sorteio from "../../../Assets/calendar.svg";

const admin = [
  { name: "Home", src: Home, rotas: "/admin/home" },
  { name: "Avisos", src: Warn, rotas: "/admin/avisos" },
  { name: "Usuários Cadastrados", src: Users, rotas: "/admin/users" },
  {
    name: "Condominio Cadastrado",
    src: condCad,
    rotas: "/admin/condominiumlist",
  },
  {
    name: "Pedidos de Cadastro",
    src: Approval,
    rotas: "/admin/managerapproval",
  },
  {
    name: "Gerencia de Propaganda",
    src: propaganda,
    rotas: "/admin/propagandalist",
  },
];

const Manager = [
  { name: "Home", src: Home, rotas: "/manager/home" },
  { name: "Avisos", src: Warn, rotas: "/manager/avisos" },
  { name: "Usuários Cadastrados", src: Users, rotas: "/manager/condominos" },
  {
    name: "Pedidos de Cadastro",
    src: Approval,
    rotas: "/manager/pedidos-de-cadastro",
  },
  { name: "Cadastrar espaço", src: Spaces, rotas: "/manager/space" },
  { name: "Sorteio de reserva", src: Sorteio, rotas: "/manager/sortition" },
  { name: "Gestão", src: Gestao, rotas: "/manager/gestao" },
  { name: "Documentos", src: Docs, rotas: "/manager/document" },
];

const administration = [
  { name: "Home", src: Home, rotas: "/administration/home" },
  { name: "Avisos", src: Warn, rotas: "/administration/avisos" },
  {
    name: "Usuários Cadastrados",
    src: Users,
    rotas: "/administration/condominos",
  },
  {
    name: "Pedidos de Cadastro",
    src: Approval,
    rotas: "/administration/pedidos-de-cadastro",
  },
  { name: "Cadastrar espaço", src: Spaces, rotas: "/administration/space" },
  {
    name: "Sorteio de reserva",
    src: Sorteio,
    rotas: "/administration/sortition",
  },
  { name: "Gestão", src: Gestao, rotas: "/administration/gestao" },
  { name: "Documentos", src: Docs, rotas: "/administration/document" },
];

const doorman = [
  { name: "Home", src: Home, rotas: "/doorman/home" },
  { name: "Avisos", src: Warn, rotas: "/doorman/avisos" },
  { name: "Condôminos", src: Users, rotas: "/doorman/residentlist" },
  { name: "Visitantes", src: Spaces, rotas: "/doorman/visitantes" },
  { name: "Correio", src: Docs, rotas: "/doorman/mail" },
  { name: "Ocorrências", src: Warn, rotas: "/doorman/ocorrencias" },
  { name: "Delivery Chegou!", src: Gestao, rotas: "/doorman/delivery" },
];

const imobiliaria = [
  {
    name: "Imóveis",
    src: condCad,
    rotas: "/imobiliaria/Imoveis",
  },
];

const publicidade = [
  {
    name: "Gerencia de Propaganda",
    src: propaganda,
    rotas: "/publicidade/propagandalist",
  },
  { name: "Serviços", src: Gestao, rotas: "/publicidade/servicos" },
];

const maintenance = [
  { name: "Ocorrências", src: Warn, rotas: "/maintenance/ocorrencias" },
  { name: "Gestão", src: Gestao, rotas: "/maintenance/gestao" },
];

const data = {
  admin: admin,
  manager: Manager,
  doorman: doorman,
  imobiliaria: imobiliaria,
  publicidade: publicidade,
  maintenance: maintenance,
  employee: administration,
};

function NewSideBar({ tipo, rota }) {
  const type = rota;
  const [userType, setUserType] = useState("");
  const { isAuthorized, signOut, token, user } = useAuth();
  const history = useHistory();
  const infos = localStorage.getItem("@SWCondominio:user");
  const [handleFile, setHandleFile] = useState("");
  const [name, setName] = useState([]);

  useEffect(() => {
    async function getProfileData() {
      console.log("Oi");
      try {
        let response;
        if (rota === "imobiliaria" || rota === "publicidade") {
          response = await api.get(`/company/${JSON.parse(infos).id}`);
        } else if (rota === "doorman" || rota === "administration") {
          response = await api.get(`/employee/${JSON.parse(infos).id}?fields=name,photo_url`);
        } else if (rota === "manager") {
          response = await api.get(`/resident/${JSON.parse(infos).id}?fields=name,photo_url`);
        } else {
          response = await api.get(`/${rota}/${JSON.parse(infos).id}`);
        }

        setName(response.data.name);
        setHandleFile(response.data.photo_url);
      } catch (error) {
        console.log(error, "teste");
      }
    }
    getProfileData();
  }, []);

  function handleSignOut() {
    signOut();
    history.push("/");
  }

  useEffect(() => {
    if (isAuthenticated()) return;
    else history.push("/");
  }, [token]);

  useEffect(() => {
    const modality = isAuthorized();
    setUserType(modality);
  }, []);

  return (
    <SideDiv>
      <SideHeader>
        <LogoDiv>
          <Logo src={handleFile ? handleFile : profilePhoto}></Logo>
        </LogoDiv>

        <DivText>
          <SideText>{name}</SideText>
          <SideText style={{ fontWeight: "800" }}>{tipo}</SideText>
          <Link
            to={`/${rota}/profile`}
            style={{ display: "flex", textDecoration: "none", color: "black" }}
          >
            {" "}
            <SideText>Meu Perfil</SideText>{" "}
          </Link>
        </DivText>
      </SideHeader>
      <DivCenter>
        <Barra marginTop={"4vh"}></Barra>
      </DivCenter>

      <DivButtons>
        {data[rota].map((item) => (
          <Link style={{ textDecoration: "none" }} to={item.rotas}>
            <SideButtons name={item.name} src={item.src}></SideButtons>
          </Link>
        ))}
      </DivButtons>

      <DivCenter>
        <Barra marginTop={"2vw"}></Barra>
      </DivCenter>

      <FooterSide>
        <Link
          to={`/${rota}/sobre`}
          style={{ textDecoration: "none", color: "white" }}
        >
          <SideButtons src={sobre} width={"35px"} name="Sobre" />
        </Link>
        <div
          onClick={() => {
            handleSignOut();
          }}
        >
          <SideButtons src={sair} name="Sair" width={"35px"} />
        </div>
      </FooterSide>
    </SideDiv>
  );
}

export default NewSideBar;
