import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../SidebarAdmin/index.css";

import SidebarOptions from "../SidebarOptions/index";

import profilePhoto from "../../Assets/Logobranca.png";

import api from "../../Services/api";

import Approval from "../../Assets/add-user.svg";
import Users from "../../Assets/users.svg";
import Warn from "../../Assets/danger.svg";
import sobre from "../../Assets/info.svg";
import Spaces from "../../Assets/spaces.svg";
import Sorteio from "../../Assets/calendar.svg";
import Home from "../../Assets/Home.svg";
import User from "../../Assets/user.svg";

import sair from "../../Assets/logout.svg";
import Gestao from "../../Assets/management.svg";
import Docs from "../../Assets/documents.svg";

import { useAuth } from "../../Context/auth";
import { useHistory } from "react-router";
import { isAuthenticated } from "../../Context/isAuthenticated";

export default function SidebarDoorman() {
  const { signOut, token, user } = useAuth();
  const history = useHistory();
  console.log(history);
  const infos = localStorage.getItem("@SWCondominio:user");
  const [handleFile, setHandleFile] = useState("");

  useEffect(() => {
    if (isAuthenticated()) return;
    else history.push("/");
  }, [token]);

  useEffect(() => {
    async function getProfileData() {
      console.log("Oi")
      try {
        const response = await api.get(`/employee/${JSON.parse(infos).id}`);
        console.log(response.data, "teste1")
        setHandleFile(response.data.photo_url);
      } catch (error) {
        console.log(error, "teste")
      }

    }
    getProfileData();
    console.log(handleFile, "asdsad");
  }, []);

  return (
    <div id="sidebarContainer">
      <div id="sidebarTopInfo">
        <div id="pic">
          <img src={handleFile ? handleFile : profilePhoto} id="logo" />
        </div>

        <div id="topInfo">
          <p id="usernameText"> {JSON.parse(infos).name} </p>
          <p id="jobText"> Porteiro </p>
          <Link
            to="/doorman/profile"
            style={{ textDecoration: "none", color: "black" }}
          >
            <p id="profileText"> Meu Perfil </p>
          </Link>
        </div>
      </div>

      <div id="bar"></div>
      <div id="sidebarMidInfo">
        <Link to="/doorman/home" style={{ textDecoration: "none" }}>
          <SidebarOptions src={Home} text="Home" />
        </Link>
        <div id="space"></div>

        <Link to="/doorman/avisos" style={{ textDecoration: "none" }}>
          <SidebarOptions src={Warn} text="Avisos" />
        </Link>
        <div id="space"></div>
        <Link to="/doorman/residentlist" style={{ textDecoration: "none" }}>
          <SidebarOptions src={Users} text="Condôminos" />
        </Link>
        <div id="space"></div>
        <Link to="/doorman/visitantes" style={{ textDecoration: "none" }}>
          <SidebarOptions src={Spaces} text="Visitantes" />
        </Link>
        <div id="space"></div>
        <Link to="/doorman/mail" style={{ textDecoration: "none" }}>
          <SidebarOptions src={Docs} text="Correio" />
        </Link>
        <div id="space"></div>
        <Link to="/doorman/ocorrencias" style={{ textDecoration: "none" }}>
          <SidebarOptions src={Warn} text="Ocorrências" />
        </Link>
        <div id="space"></div>
        <Link to="/doorman/delivery" style={{ textDecoration: "none" }}>
          <SidebarOptions src={Gestao} text="Delivery Chegou!" />
        </Link>
      </div>
      <div id="bar"></div>

      <div id="sidebarBottomInfo">
        <Link to="/doorman/sobre" style={{ textDecoration: "none" }}>
          <SidebarOptions src={sobre} text="Sobre" />
        </Link>
        <SidebarOptions src={sair} text="Sair" onClick={signOut} />
      </div>
    </div>
  );
}
