import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

// Auth
import { useAuth } from "../../../../Context/auth";

// Icons
import SearchIcon from "@material-ui/icons/Search";

// Service
import api from "../../../../Services/api";

// Toast
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Components
import Sidebar from "../../../../Components/SidebarManager";
import ArrowBack from "../../../../Components/ResponsiveArrowBack";
import { InputWrap } from "../Management/Legislation/AddLei/style";

// Assets
import plus from "../../../../Assets/plus.svg";

// Style
import "./index.css";
import { Grid } from "@material-ui/core";
import NewSideBar from "../../../../Components/NewComponents/NewSideBar";

export default function SpacePageAdministrarion() {
  const history = useHistory();

  const { user } = useAuth();

  const [name, setName] = useState("");
  const [spaces, setSpaces] = useState([]);
  const infos = localStorage.getItem("@SWCondominio:user");
  const [filter, setFilter] = useState("name");
  const [procurar, setProcurar] = useState("");

  const notifyError = () =>
    toast.error(
      "Desculpe, houve uma falha ao obter dados dos sorteios. Tente novamente mais tarde."
    );
  const notifyDeleteError = () => toast.error("Erro ao deletar espaço!");
  const notifyDelete = () => toast.success("Espaço deletado!");

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await api.get(`/space`);
        const space = response.data;
        setSpaces(space);
        console.log(space);
      } catch (e) {
        notifyError();
        throw e;
      }
    };
    getData();
  }, []);

  useEffect(() => {
    function getProfileData() {
      api.get(`/resident/${JSON.parse(infos).id}`).then((response) => {});
    }
    getProfileData();
  }, []);

  async function deleteItem(id) {
    try {
      await api.delete(`/space/${id}`);
      setSpaces(spaces.filter((space) => space.id !== id));
      notifyDelete();
    } catch (error) {
      notifyDeleteError();
      throw error;
    }
  }

  /* const converthour = (minutos) => {
      const horas = Math.floor(minutos/ 60);          
      const min = minutos % 60;
      const textoHoras = (`00${horas}`).slice(-2);
      const textoMinutos = (`00${min}`).slice(-2);
      
      return `${textoHoras }:${textoMinutos}:00`;
    }; */

  return (
    <Grid
      container
      style={{
        flexDirection: "row",
        borderColor: "black",
        borderStyle: "solid",
        border: "10px",
        width: "100vw",
        display: "flex",
      }}
    >
      {/*<Grid item xs={3} style={{borderStyle:"solid",borderColor:"red"}}>}
        //</Grid>*/}
      <NewSideBar rota={"employee"} tipo={"Administrativo"}></NewSideBar>
      <Grid
        container
        item
        xs={8}
        style={{
          paddingLeft: "56px",
          width: "100vw",
        }}
      >
        <Grid item xs={12}>
          <div className="pageTitleContainer" style={{ margin: "0px" }}>
            <ArrowBack></ArrowBack>
            <p className="pageTitle">Criar Espaço</p>
          </div>
          <div style={{ display: "flex", marginLeft: "50px" }}>
            {filter ? (
              <div id="searchContainer">
                <SearchIcon />
                <input
                  id="searchInput"
                  placeholder="Pesquisar"
                  onChange={(event) => {
                    setProcurar(event.target.value);
                  }}
                />
              </div>
            ) : null}
            <InputWrap style={{ marginBottom: "32px", marginLeft: "10px" }}>
              <select
                style={{
                  border: "none",
                  borderLeft: "4px solid #00334E",
                  borderRadius: "20px",
                  backgroundColor: "#f8f8f8",
                  padding: "4px 12px",
                }}
                placeholder="None"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              >
                <option value="name">Nome do local</option>
                <option value="capacity">Capacidade (quantidade)</option>
                <option value="max_minutes_duration">
                  Duração máxima (horas)
                </option>
                <option value="maximum_days">Antecedência máxima (dias)</option>
                <option value="min_minutes_interval">
                  Intervalo mínimo (horas)
                </option>
                <option value="price">Preço (R$)</option>
                <option value="minimum_time">Horário de entrada (--:--)</option>
                <option value="cancelation_days">
                  Antecedência mínima para cancelamento (dias)
                </option>
                <option value="percentage">Percentual de Multa (%)</option>
              </select>
            </InputWrap>
          </div>
          <Link
            to={{
              pathname: "/administration/space/create",
              state: { data: "asdasd" },
            }}
            style={{ textDecoration: "none", color: "black" }}
            params={{ test: "asdafdasfsd" }}
          >
            <div className="addButton">
              <img src={plus} id="plusIcon" alt="adicionar contrato" />
              <p>Cadastrar novo espaço</p>
            </div>
          </Link>
        </Grid>
        <Grid container spacing={2} justify="space-between">
          {spaces &&
            spaces
              .filter((space) => {
                if (procurar === "") {
                  return space;
                } else if (
                  space?.name.toLowerCase().includes(procurar.toLowerCase()) &&
                  filter === "name"
                ) {
                  return space;
                } else if (
                  space?.capacity
                    .toLowerCase()
                    .includes(procurar.toLowerCase()) &&
                  filter === "capacity"
                ) {
                  return space;
                } else if (
                  space?.max_minutes_duration
                    .toLowerCase()
                    .includes(procurar.toLowerCase()) &&
                  filter === "max_minutes_duration"
                ) {
                  return space;
                } else if (
                  space?.maximum_days
                    .toLowerCase()
                    .includes(procurar.toLowerCase()) &&
                  filter === "maximum_days"
                ) {
                  return space;
                } else if (
                  space?.min_minutes_interval
                    .toLowerCase()
                    .includes(procurar.toLowerCase()) &&
                  filter === "min_minutes_interval"
                ) {
                  return space;
                } else if (
                  space?.price.toLowerCase().includes(procurar.toLowerCase()) &&
                  filter === "price"
                ) {
                  return space;
                } else if (
                  space?.minimum_time
                    .toLowerCase()
                    .includes(procurar.toLowerCase()) &&
                  filter === "minimum_time"
                ) {
                  return space;
                } else if (
                  space?.cancelation_days
                    .toLowerCase()
                    .includes(procurar.toLowerCase()) &&
                  filter === "cancelation_days"
                ) {
                  return space;
                } else if (
                  space?.percentage
                    .toLowerCase()
                    .includes(procurar.toLowerCase()) &&
                  filter === "percentage "
                ) {
                  return space;
                }
              })
              .map((space) => (
                <Grid item xs={6}>
                  <div
                    className="dataContainer-lg"
                    style={{ minHeight: "450px" }}
                  >
                    <div className="infoLabel">Nome: {space?.name}</div>
                    <div className="infoLabel">
                      Capacidade: {space?.capacity} pessoas
                    </div>
                    <div className="infoLabel">
                      Duração máxima:{" "}
                      {/*converthour(space?.max_minutes_duration)*/}
                      {space?.max_minutes_duration} horas
                    </div>
                    <div className="infoLabel">
                      Antecedência máxima: {space?.maximum_days} dias
                    </div>
                    <div className="infoLabel">
                      Intervalo mínimo: {space?.min_minutes_interval} horas
                    </div>
                    <div className="infoLabel">
                      Preço: {`R$ ${space?.price?.replace("R$", "") ?? "00"}`}
                    </div>
                    <div className="infoLabel">
                      Horário de entrada: {space?.minimum_time.slice(0, 5)}
                    </div>
                    <div className="infoLabel">
                      Antecedência mínima para cancelamento:{" "}
                      {space?.cancelation_days} dias
                    </div>
                    <div className="infoLabel">
                      Percentual de Multa:{" "}
                      {space?.percentage ? space.percentage : "--"} %
                    </div>

                    <Grid
                      container
                      style={{ justifyContent: "flex-end", gap: "30px" }}
                    >
                      <Grid item xs={1}>
                        <button
                          onClick={() => {
                            history.push(`/administration/space/edit/${space.id}`);
                          }}
                          className="actionButton edit"
                        >
                          Editar
                        </button>
                      </Grid>
                      <Grid item xs={1}>
                        <button
                          onClick={() => {
                            deleteItem(space?.id);
                          }}
                          style={{ marginLeft: "10px" }}
                          className="actionButton delete"
                        >
                          Apagar
                        </button>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
