import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SindicoImg from "../../../../Assets/menuImages/sindico.png";
import GestaoImg from "../../../../Assets/menuImages/gestao.jpeg";
import OcorrenciaImg from "../../../../Assets/menuImages/ocorrencias.png";
import DeliveryImg from "../../../../Assets/menuImages/delivery.png";
import CorrespondenciaImg from "../../../../Assets/menuImages/correspondencia.png";
import VendasImg from "../../../../Assets/menuImages/venda.png";
import ReservasImg from "../../../../Assets/menuImages/reservas.png";
import DocsImg from "../../../../Assets/menuImages/docs.png";
import ServicosImg from "../../../../Assets/menuImages/servicos.png";
import VisitantesImg from "../../../../Assets/menuImages/visitantes.png";
import ChatImg from "../../../../Assets/menuImages/chat.png";
import NotasImg from "../../../../Assets/menuImages/notas.png";

import api from "../../../../Services/api";
//Auth
import { useAuth } from "../../../../Context/auth";
//Components
//import Loading from "../../../../Components/Loading/index";
import SidebarDoorman from "../../../../Components/SideBarDoorman";
import NewSideBar from "../../../../Components/NewComponents/NewSideBar";
//import SidebarOptions from "../../../../Components/SidebarOptions";

import plus from "../../../../Assets/plus.svg";

import "./index.css";

export default function HomepageDoorman() {
  const cards = [
    { name: "Condôminos", img: SindicoImg, link: "/doorman/residentlist" },
    { name: "Ocorrências", img: OcorrenciaImg, link: "/doorman/ocorrencias" },
    { name: "Delivery chegou", img: DeliveryImg, link: "/doorman/delivery" },
    { name: "Correios", img: CorrespondenciaImg, link: "/doorman/mail" },
    {
      name: "Visitantes",
      img: VisitantesImg,
      link: "/doorman/visitantes",
    },
    { name: "Bloco de notas", img: NotasImg, link: "/doorman/notes" },
  ];

  return (
    <div id="homeContent">
      <NewSideBar rota={"doorman"} tipo={"Porteiro"}></NewSideBar>
      <div id="homeCenter" style={{ width: "100%", overflowY: "auto", height: "100%" }}>
        {/*
          <p className="welcomeTitle" id="homeWelcome">
            Bem vindo, {name}!
          </p>
          <p className="welcomeTitle" id="homeWelcome">
            Bem vindo, {user.name}!
          </p>
          <Link
              to="/manager/criar-aviso"
              style={{ textDecoration: "none", color: "black" }}
            >
          <div id="option">
              <img src={plus} id="plusIcon" />
              <p>Criar aviso</p>
          </div>
          </Link>
        */}
        {cards.map((card) => (
          <Link
            style={{ textDecoration: "none" }}
            to={card.link ? card.link : "/doorman/home"}
          >
            <div id="home-card">
              <img src={card.img} />
              <h1>{card.name}</h1>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}
